import { camelize } from "humps"

export const updateConsentAndAuthorization = ({
  sectionFormFields,
  accessRule,
}) => {
  sectionFormFields.forEach((section) => {
    if (section.section === "Consent and Authorization") {
      section.fields.forEach((field) => {
        if (field.name === "consentAndAuthorization") {
          let replacedOptions = field.options.map((option) => {
            // Match all href links in the option value
            let linksInOption = option.match(/href="\/.+?"/g)
            if (linksInOption?.length) {
              linksInOption.forEach((linkInOption, index) => {
                // Parse existing href link from the option
                linkInOption = linkInOption.split("/")[1].slice(0, -1)
                let linkLabel = camelize(linkInOption)

                // If found href link has a redirect link, change the href link

                if (linkLabel in accessRule.redirectLinks) {
                  let newLink = `https://${accessRule.redirectLinks[linkLabel]}`
                  option = option.replace(`/${linkInOption}`, newLink)
                }
              })
            }

            return option
          })

          replacedOptions = replacedOptions.filter(
            (option) => !option.toLowerCase().includes("maxicare")
          )

          field.options = replacedOptions
        }
      })
    }
  })

  return sectionFormFields
}
