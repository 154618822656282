import React, { useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"
import { get } from "lodash"
import classNames from "classnames"

import Layout from "layout"
import Section from "elements/Section"
import Container from "layout/Container"
import FileThumbnail from "elements/UploadDocumentDropzone/FileThumbnail"
import EditDetailsButton from "elements/EditDetailsButton"
import BookingSummaryConsent from "./BookingSummaryConsent"

import { isBrowser } from "services/general"
import { ConsultationContext } from "../../ConsultationContext/ConsultationContext"
import { useMentalHealthFormFields } from "../hooks/useAirtableMHFormFields"
import { sendTeleconsultRequest } from "../services/booking"
import { getSignedInUser } from "../../../Auth/services/user"
import { updateConsentAndAuthorization } from "../services/updateConsentAndAuthorization"

import styles from "./utils/bookingSummary.module.scss"

let { parseFormField } = require("services/airtable")

const MentalSummary = ({ pageContext }) => {
  const { consultationState, consultationDispatch } = useContext(
    ConsultationContext
  )
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (isBrowser()) {
      consultationDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    }
  }, [consultationDispatch])

  let { sectionFormFields, validationSchema } = useMentalHealthFormFields({
    formFields: pageContext.formFields,
  })

  // Get only consentAndAuthorization field under Schedule module
  sectionFormFields = sectionFormFields.filter(
    (section) => section.section === "Consent and Authorization"
  )

  const accessRule = getSignedInUser().accessRule

  if (accessRule?.redirectLinks) {
    sectionFormFields = updateConsentAndAuthorization({
      sectionFormFields,
      accessRule,
    })
  }

  let sectionSummaryFields = parseFormField(
    pageContext.summaryFields.map((formField) => ({
      ...formField,
      section: formField.summarySection,
    }))
  )

  sectionSummaryFields = sectionSummaryFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )

  // Hardcoded values based on path values for each page
  const editKey = {
    "Medical History": "/booking/mind/intake",
    Screening: "/booking/mind/intake",
    Assessment: "/booking/mind/support",
    Schedule: pageContext.backPath,
  }

  const handleSubmit = async () => {
    setLoading(true)
    await sendTeleconsultRequest({
      consultationState: consultationState,
      callback: () => {
        setLoading(false)
        navigate(pageContext.nextPath)
      },
    })
  }

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.title}
      pageContext={pageContext}
      verifyPage
      isPrivate
      progress
      isForms
    >
      <Container isCentered>
        {sectionSummaryFields.map((section) => {
          return (
            <Section
              title={section?.section}
              addOns={{
                right: editKey[section?.section] ? (
                  <EditDetailsButton route={editKey[section?.section]} />
                ) : null,
              }}
            >
              <div className="table-container mb-1-mobile">
                <table class="table is-fullwidth message-text-size">
                  <tbody>
                    {section.fields
                      .filter((field) => {
                        // Only display the fields related to Mental Health
                        return field.program.includes("MGX1SOLMH")
                      })
                      .map((field) => {
                        let finalValue = null
                        switch (field.type) {
                          case "select":
                            finalValue = get(consultationState, field.name)
                              ? get(consultationState, field.name)?.label
                              : ""
                            break
                          case "date":
                            finalValue = get(consultationState, field.name)
                              ? `${
                                  get(consultationState, field.name)?.month
                                    ?.label
                                } ${
                                  get(consultationState, field.name)?.date
                                    ?.label
                                }, ${
                                  get(consultationState, field.name)?.year.label
                                }`
                              : ""
                            break
                          case "address":
                            finalValue = get(consultationState, field.name)
                              ? `${
                                  get(consultationState, field.name)
                                    ?.streetAddress
                                }, ${
                                  get(consultationState, field.name)?.city
                                    ?.label
                                }, ${
                                  get(consultationState, field.name)?.province
                                    ?.label
                                }`
                              : ""
                            break
                          case "upload":
                            finalValue = consultationState.documents?.map(
                              (document) => <FileThumbnail file={document} />
                            )
                            finalValue = !finalValue.length && null
                            break
                          case "schedule":
                            finalValue = get(consultationState, field.name)
                              ? `${
                                  get(consultationState, field.name)?.day?.label
                                }, ${
                                  get(consultationState, field.name)?.time
                                    ?.label
                                }`
                              : ""
                            break
                          case "checkbox":
                            if (Array.isArray(consultationState[field.name])) {
                              finalValue = consultationState[field.name].join(", ");
                            } else {
                              finalValue = consultationState[field.name];
                            }
                            break
                          default:
                            finalValue = get(consultationState, field.name)
                            break
                        }

                        if (!!finalValue)
                          return (
                            <tr>
                              <td className="has-text-weight-bold py-1">
                                {field.summaryLabel}
                              </td>
                              <td
                                className={classNames(
                                  "py-1 pl-2",
                                  styles["summary__tableData"]
                                )}
                              >
                                {finalValue}
                              </td>
                            </tr>
                          )
                        return null
                      })}
                  </tbody>
                </table>
              </div>
            </Section>
          )
        })}
        <BookingSummaryConsent
          sectionFormFields={sectionFormFields}
          validationSchema={validationSchema}
          handleSubmit={handleSubmit}
          pageContext={pageContext}
          loading={loading}
          accessRule={accessRule}
        />
      </Container>
    </Layout>
  )
}

export default MentalSummary
